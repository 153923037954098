import PageTitle from "../Components/PageTitle";
import {Link} from "react-router-dom";
import BlogPostListingCard from "../Components/Blog/BlogPostListingCard";
import SectionTitle from "../Components/SectionTitle";

function BlogPage() {
  const blogPosts = [
    {
      id: 1,
      title: "Fernando Pessoa: The Enigmatic Poet",
      smallDesc: <p className={"mb-3"}>Fernando Pessoa is one of the most celebrated Portuguese poets of the 20th century. His unique literary style, which combined a vast range of voices and personas, made him a literary icon and an enigma at the same time. In this blog post, we will explore his life, works, and legacy.</p>,
      date: "2023-02-15",
      categories: [
        {
          id: 1,
          name: "poetry"
        }, {
          id: 2,
          name: "biography"
        }, {
          id: 3,
          name: "research"
        }],
      image: "https://source.unsplash.com/random/1024x768",
      content: <><p className={"mb-3"}>Fernando Pessoa is one of the most celebrated Portuguese poets of the 20th century. His unique literary style, which combined a vast range of voices and personas, made him a literary icon and an enigma at the same time. In this blog post, we will explore his life, works, and legacy.</p>
        <h1 className="text-2xl">Early Life</h1>

        <p className={"mb-3"}>Fernando António Nogueira Pessoa was born on June 13, 1888, in Lisbon, Portugal. His parents separated when he was young, and he was raised by his mother and stepfather. Pessoa was a gifted student, but he struggled with socializing and making friends. He began writing poetry at a young age, and by the time he was a teenager, he was already publishing his works in local newspapers.</p>
        <h1 className="text-2xl">Literary Career</h1>

        <p className={"mb-3"}>Pessoa's literary career began in earnest in 1914 when he published a collection of poems titled "Mensagem" (Message). The book was a critical success and established Pessoa as a leading voice in Portuguese literature. In the following years, he continued to publish poetry, as well as essays and literary criticism.</p>

        <p className={"mb-3"}>One of Pessoa's most significant contributions to literature was his development of heteronyms, which were different personas or alter egos that he created for himself. Each heteronym had its own unique style, voice, and biography, and Pessoa would often publish works under their names instead of his own. This technique allowed him to explore a vast range of themes and ideas, and it remains one of the most distinctive features of his literary legacy.</p>

        <h2 className="text-xl">Heteronyms</h2>
        <p className="mb-3">Here are some of the most famous heteronyms that Fernando Pessoa created:</p>
        <ul className={"list-disc mb-5 space-y-1 list-inside"}>
          <li><strong>Alberto Caeiro</strong>: a rural poet who celebrated the beauty of nature and rejected traditional philosophy and metaphysics.</li>
          <li><strong>Ricardo Reis</strong>: a classical scholar who wrote poetry inspired by ancient Greek and Roman literature.</li>
          <li><strong>Álvaro de Campos</strong>: an avant-garde poet who celebrated modernity and technology and experimented with free verse and surreal imagery.</li>
          <li><strong>Bernardo Soares</strong>: a melancholic poet who wrote prose poems about everyday life in Lisbon.</li>
        </ul>

        <p className={"mb-3"}>Pessoa also created many other heteronyms, some of whom had only a few works attributed to them, and some of whom had more extensive biographies and bodies of work. Overall, the use of heteronyms allowed Pessoa to explore a vast range of themes and styles, and his work remains a testament to his creativity and originality.</p>

        <p className={"mb-3"}>There is a great number of heteronyms, many of which were only used for a few poems or texts, and others that had more extensive biographies and works attributed to them. Here are a few examples of other heteronyms Pessoa created:</p>
        <ul className={"list-disc mb-5 space-y-1 list-inside"}>
          <li><strong>Antonio Mora</strong>: a spiritualist poet who wrote about religion and the afterlife.</li>
          <li><strong>Charles Robert Anon</strong>: a British poet who wrote in English and represented a more traditional poetic voice.</li>
          <li><strong>Alexander Search</strong>: a British poet who wrote in English and was Pessoa's first heteronym.</li>
          <li><strong>Jean Seul de Méluret</strong>: a French poet who wrote in a traditional, romantic style.</li>
          <li><strong>I. I.</strong>: an Austrian philosopher who wrote about idealism and metaphysics.</li>
        </ul>
        <p className={"mb-3"}>These are just a few examples, but there are many other heteronyms that Pessoa created throughout his life. Each one had its own unique voice and style, and the use of heteronyms allowed Pessoa to explore a vast range of themes and ideas in his writing.</p>

        <h1 className="text-2xl">Legacy</h1>

        <p className={"mb-3"}>Fernando Pessoa passed away on November 30, 1935, at the age of 47, leaving behind a vast collection of unpublished works. His poems, essays, and heteronyms continue to captivate readers to this day, and he is considered one of the most important figures in Portuguese literature. His works have been translated into many languages, and his influence can be seen in the works of countless writers and poets around the world.</p>

        <p className={"mb-3"}>In conclusion, Fernando Pessoa was a literary giant whose unique style and approach to poetry set him apart from his contemporaries. His legacy continues to inspire and challenge readers, and his contribution to the literary world will undoubtedly endure for generations to come.</p></>
    },
    {
      id:2,
      title: "The Ocean, Water, and Love",
      smallDesc: <p className={"mb-3"}>The ocean is one of the most powerful and enigmatic forces of nature. Its vastness, depth, and mystery have captivated poets, artists, and philosophers for centuries. But what is it about the ocean that draws us to it, that inspires us to contemplate its meaning and significance? In this blog post, we will explore the philosophical connections between the ocean, water, and love.</p>,
      date: "2023-02-15",
      categories: [
        {
          id: 4,
          name: "philosophy"
         },
         {
          id: 5,
          name: "ocean"
         }, {
          id: 6,
          name: "water"
         }, {
          id: 7,
          name: "love"
         }
      ],
      image: "https://source.unsplash.com/random/800x600",
      content: <>
        <p className={"mb-3"}>The ocean is one of the most powerful and enigmatic forces of nature. Its vastness, depth, and mystery have captivated poets, artists, and philosophers for centuries. But what is it about the ocean that draws us to it, that inspires us to contemplate its meaning and significance? In this blog post, we will explore the philosophical connections between the ocean, water, and love.</p>
        <h1 className="text-2xl">Water as a Symbol of Life</h1>

        <p className={"mb-3"}>Water is essential to life. Without it, we could not survive. Water nourishes us, cleanses us, and sustains us. But water is more than just a physical substance. It is also a powerful symbol of life and vitality. Water represents the flow of energy, the cycle of birth and death, and the inexorable passage of time. In this sense, water is a reflection of our own mortality, a reminder that life is fleeting and precious.</p>
        <h1 className="text-2xl">The Ocean as a Symbol of Mystery</h1>

        <p className={"mb-3"}>The ocean is both beautiful and terrifying. Its vastness and depth remind us of the mysteries that lie beyond our understanding. The ocean is a symbol of the unknown, of the depths of the human psyche that we have yet to explore. The ocean represents the vastness of the universe, and our own insignificance in the face of its immensity. It is a reminder that there is more to life than what we can see and understand.</p>
        <h1 className="text-2xl">Love as a Force of Nature</h1>

        <p className={"mb-3"}>Love is a powerful force that drives us to connect with others, to form bonds of intimacy and trust. Love is also a force of nature, a reflection of the energy that flows through all living things. Love is the binding force that connects us to each other and to the world around us. It is the force that creates and sustains life, and that gives meaning to our existence.</p>
        <h1 className="text-2xl">The Ocean, Water, and Love</h1>

        <p className={"mb-3"}>The ocean, water, and love are all connected in a profound way. The ocean is a symbol of the mystery and depth of life, while water represents the flow of energy and the cycle of birth and death. Love, in turn, is the force that binds us to each other and to the world around us. When we stand on the shore and gaze out at the ocean, we are reminded of the vastness and power of nature, and of the mysteries that lie beyond our understanding. But we are also reminded of the beauty and interconnectedness of life, and of the power of love to unite us all.</p>

        <p className={"mb-3"}>In conclusion, the ocean, water, and love are all powerful symbols of the human experience. They remind us of the mysteries and beauty of life, and of our own mortality and interconnectedness. The next time you stand on the shore and gaze out at the ocean, take a moment to contemplate the profound connections between these forces of nature, and the way they shape our understanding of ourselves and the world around us.</p>
      </>
    }
  ];

  return (
    <div id={"content"} className={"container sm:px-0 lg:px-20 mx-auto"}>
      <section className={"my-10"}>
        <PageTitle title={"Blog"} />
      </section>
      <section className={"my-10"}>
        {/* featured posts slideshow */}
        <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 lg:grid-cols-3">
          <BlogPostListingCard 
            title="This is a test post without image"
            categories={[{id:1, name:"Technology"},{id:2, name:"Lifestyle"}]} 
            description="This is a test description for this test post which may not be reliable."
            date="02/10/2022"
            link="/blog/2"
          />
          <BlogPostListingCard 
            title="This is a test post with a random image" 
            categories={[{id:1, name:"Technology"}]} 
            description="This is a test description for this test post which may not be reliable."
            date="02/10/2022"
            link="/blog/1"
            image="https://source.unsplash.com/random/640x480"
          />
          <BlogPostListingCard 
            title="This is a test post with a random image" 
            categories={[{id:1, name:"Technology"},{id:2, name:"Lifestyle"}]} 
            description="This is a test description for this test post which may not be reliable."
            date="02/10/2022"
            link="/blog/3"
            image="https://source.unsplash.com/random/640x480"
          />
        </div>
      </section>
      <section className={"grid grid-cols-4 gap-4"}>
        <div className={"col-span-4 md:col-span-3 mx-5"}>
          <SectionTitle title={"Recent Posts"} />
          {/* posts with horizontal cards with the image in the same line as post title and description */}

          {blogPosts.map((post, index) => {
            return (
              <BlogPostListingCard 
                key={post.id}
                title={post.title}
                categories={post.categories}
                description={post.content.props.children[0]}
                date={post.date}
                link={"/blog/" + post.id}
                image={post.image}
                type={"horizontal"}
              />
            )
          })}

          <BlogPostListingCard 
            title="This is a test post with a random image" 
            categories={[{id:1, name:"Technology"},{id:2, name:"Lifestyle"}]} 
            description="This is a test description for this test post which may not be reliable."
            date="02/10/2022"
            link="/blog/3"
            image="https://source.unsplash.com/random/640x480" 
            type={"horizontal"} />

          <BlogPostListingCard 
            title="This is a test post with a random image" 
            categories={[{id:1, name:"Technology"},{id:2, name:"Lifestyle"}]} 
            description="This is a test description for this test post which may not be reliable."
            date="02/10/2022"
            link="/blog/3"
            image="https://source.unsplash.com/random/640x480" 
            type={"horizontal"} />
          
          <BlogPostListingCard 
            title="This is a test post without image" 
            categories={[{id:1, name:"Technology"},{id:2, name:"Lifestyle"}]} 
            description="This is a test description for this test post which may not be reliable."
            date="02/10/2022"
            link="/blog/3"
            type={"horizontal"} />
                  
        </div>
        <aside className="">
          <div className={"mb-4"}>
            <h2 className={"text-xl font-bold pb-4"}>Categorias</h2>
            <ul className={"list-none"}>
              <li className={"border-b last:border-none py-2"}>
                <Link to={"/blog"} className={"flex justify-between"}>
                  <span className={"font-semibold"}>Opinião</span>
                  <span className={"bg-gray-200 rounded-full px-2"}>2</span>
                </Link>
              </li>
              <li className={"border-b last:border-none py-2"}>
                <Link to={"/blog"} className={"flex justify-between"}>
                  <span className={"font-semibold"}>Lifestyle</span>
                  <span className={"bg-gray-200 rounded-full px-2"}>2</span>
                </Link>
              </li>
              <li className={"border-b last:border-none py-2"}>
                <Link to={"/blog"} className={"flex justify-between"}>
                  <span className={"font-semibold"}>Tecnologia</span>
                  <span className={"bg-gray-200 rounded-full px-2"}>2</span>
                </Link>
              </li>
              <li className={"border-b last:border-none py-2"}>
                <Link to={"/blog"} className={"flex justify-between"}>
                  <span className={"font-semibold"}>Viagens</span>
                  <span className={"bg-gray-200 rounded-full px-2"}>2</span>
                </Link>
              </li>
            </ul>
          </div>
          <hr className={"my-5"} />
          <div>
            <h2 className={"text-xl font-bold"}>Newsletter</h2>
            <p className={"mt-2"}>Receba as últimas notícias e artigos no seu email.</p>
            <form className={"mt-2"}>
              <input type={"email"} placeholder={"Email"} className={"border border-gray-300 rounded-md px-2 py-1 w-full"} />
              <button className={"bg-blue-500 hover:bg-blue-600 transition-all text-white rounded-md px-3 py-2 mt-2"}>Subscrever</button>
            </form>            
          </div>
        </aside>
      </section>
    </div>
  );
}

export default BlogPage;
interface PageTitle_Props {
  title: string;
}

function PageTitle(props: PageTitle_Props) {
  return (
    <h1 className={"text-4xl font-bold"}>{props.title}<span className={"underline underline-offset-4 relative top-3 right-5"}>&nbsp;&nbsp;&nbsp;</span></h1>
  )
}

export default PageTitle;
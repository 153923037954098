import { Link } from "react-router-dom";

export default function CategoryElement({id, name}: {id: number, name: string}){
  return (
    <li className="inline-block mr-1 last:mr-0">
      <Link to={ "/blog/category/" + id } className={`px-3 py-1 text-sm font-medium transition-colors duration-150 border border-transparent rounded-full focus:outline-none text-slate-800 bg-slate-200 dark:text-slate-50 dark:bg-slate-700 hover:bg-slate-300 dark:hover:bg-slate-600 focus:shadow-outline-slate`}>
        <span>{ name }</span>
      </Link>
    </li>
  );
}
import { Link } from "react-router-dom";
import CategoryElement from "./BlogPostListingComponents/CategoryElement";

interface Category{
  id: number;
  name: string;
}

interface BlogPostListingCardType {
  type?: string;
  title: string;
  date: string;
  description: string;
  image?: string;
  categories: Category[];
  link: string;
}

function BlogPostListingCard({title, date, description, image, categories, link, type = "vertical"}: BlogPostListingCardType) {
  return type === 'horizontal' ? 
  (
    <article className="dark:bg-gray-800 dark:text-gray-50 pr-4 py-3 mb-3 rounded-xl shadow-md hover:shadow-lg transition-all bg-white">
      <div className="container grid grid-cols-12 mx-auto dark:bg-gray-900">
      
        { image !== undefined && (<div className="bg-no-repeat bg-cover dark:bg-gray-700 col-span-full lg:col-span-4 shadow-md rounded-lg" style={{ backgroundImage: "url('" + image + "')", backgroundPosition: "center center", backgroundBlendMode: "multiply", backgroundSize: "cover" }}></div>)}
        
        <div className="flex flex-col p-6 col-span-full row-span-full lg:col-span-8 lg:p-10">
          <Link to={ link }>
          <h1 className="text-3xl font-semibold">{ title }</h1>
          <p className="flex-1 pt-2">{ description }</p>
          <div className="inline-flex items-center pt-2 pb-6 space-x-2 text-sm dark:text-violet-400 hover:font-bold transition-all">
            <span>Read more</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>
          </div>
          </Link>
          <div className="flex justify-start">
            <ul className="list-inline list-none">
              {categories.map((category, index) => (
                <CategoryElement key={index} id={category.id} name={category.name} />
              ))}
            </ul>
          </div>
        </div>
        
      </div>
      
    </article>
  ) 
  : 
  (
    <article className="flex flex-col overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-all">
      
      { 
      image !== undefined && 
        (
          <div className="flex-shrink-0">
            <img className="object-cover w-full h-48" src={ image } alt="" />
          </div>
        ) 
      }
      
      <div className="flex-1 p-6 bg-white dark:bg-gray-800">
        <Link to={ link }>
        <div className="flex flex-wrap">
          <div className="w-full text-xl font-semibold text-gray-800 dark:text-white">
            { title }
          </div>
          <div className="w-full text-sm font-medium text-gray-500 dark:text-gray-400">
            <time dateTime="2020-03-16">{ date }</time>
          </div>
        </div>
        <p className="mt-2 text-base text-gray-500 dark:text-gray-400">
          { description }
        </p>
        </Link>
        <ul className="list-inline list-none mt-4">
          {categories.map((category, index) => (
            <CategoryElement key={index} id={category.id} name={category.name} />
          ))}
        </ul>
      </div>
      
    </article>
  );
}

export default BlogPostListingCard;
import { Link } from "react-router-dom";
import PageTitle from "../Components/PageTitle";

function PortfolioPage() {
  return (
    <div id={"content"} className={"my-6 container px-10 mx-auto"}>
      <section className={"my-10"}>
        <PageTitle title={"Portfolio"} />
      </section>
      <section>
        {/* section of portfolio items, disposed on a grid */}
        <div className="grid-cols-3 p-5 space-y-2 bg-slate-100 dark:bg-slate-900 lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-3">
          <div className="flex flex-col justify-between p-5 bg-white rounded-lg shadow-lg dark:bg-slate-800">
            <div className="flex flex-col">
              <h4 className="mb-3 text-lg font-semibold text-gray-800 dark:text-gray-200">Portfolio Item 1</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptates.</p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <Link to="#" className="text-sm font-medium text-indigo-500 dark:text-indigo-100 hover:underline">Read More</Link>
              <div className="flex items-center">
                <Link to="#" className="text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
                <Link to="#" className="ml-4 text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 bg-white rounded-lg shadow-lg dark:bg-slate-800">
            <div className="flex flex-col">
              {/* portfolio item image */}
              <img src="https://images.unsplash.com/photo-1517436073-3b1b1f7d4d9a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cG9ydGZvbGlvJTIwaW1hZ2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80" alt="portfolio item" className="object-cover object-center w-full h-56 mb-4 rounded-lg" />
            </div>
            <div className="flex flex-col">
              <h4 className="mb-3 text-lg font-semibold text-gray-800 dark:text-gray-200">Portfolio Item 1</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptates.</p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <Link to="#" className="text-sm font-medium text-indigo-500 dark:text-indigo-100 hover:underline">Read More</Link>
              <div className="flex items-center">
                <Link to="#" className="text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
                <Link to="#" className="ml-4 text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 bg-white rounded-lg shadow-lg dark:bg-slate-800">
            <div className="flex flex-col">
              <h4 className="mb-3 text-lg font-semibold text-gray-800 dark:text-gray-200">Portfolio Item 1</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptates.</p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <Link to="#" className="text-sm font-medium text-indigo-500 dark:text-indigo-100 hover:underline">Read More</Link>
              <div className="flex items-center">
                <Link to="#" className="text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
                <Link to="#" className="ml-4 text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 bg-white rounded-lg shadow-lg dark:bg-slate-800">
            <div className="flex flex-col">
              <h4 className="mb-3 text-lg font-semibold text-gray-800 dark:text-gray-200">Portfolio Item 1</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptates.</p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <Link to="#" className="text-sm font-medium text-indigo-500 dark:text-indigo-100 hover:underline">Read More</Link>
              <div className="flex items-center">
                <Link to="#" className="text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
                <Link to="#" className="ml-4 text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 bg-white rounded-lg shadow-lg dark:bg-slate-800">
            <div className="flex flex-col">
              <h4 className="mb-3 text-lg font-semibold text-gray-800 dark:text-gray-200">Portfolio Item 1</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptates.</p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <Link to="#" className="text-sm font-medium text-indigo-500 dark:text-indigo-100 hover:underline">Read More</Link>
              <div className="flex items-center">
                <Link to="#" className="text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
                <Link to="#" className="ml-4 text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 bg-white rounded-lg shadow-lg dark:bg-slate-800">
            <div className="flex flex-col">
              <h4 className="mb-3 text-lg font-semibold text-gray-800 dark:text-gray-200">Portfolio Item 1</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptates.</p>
            </div>
            <div className="flex items-center justify-between mt-6">
              <Link to="#" className="text-sm font-medium text-indigo-500 dark:text-indigo-100 hover:underline">Read More</Link>
              <div className="flex items-center">
                <Link to="#" className="text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
                <Link to="#" className="ml-4 text-gray-600 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-400">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-5a5 5 0 100 10 5 5 0 000-10z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
       </div>
      </section>
    </div>
  );
}

export default PortfolioPage;
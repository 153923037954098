import PageTitle from "../Components/PageTitle";
import React, { useEffect, useState } from 'react';
import style from "./Gallery.module.css";
import Masonry from "react-masonry-css";

export interface GalleryItemProps {
  id: number;
  alt: string;
  width: number;
  height: number;
  url: string;
  download_url?: string;
};

interface GalleryProps {
  images: GalleryItemProps[];
}

const Gallery: React.FC<GalleryProps> = (props: GalleryProps) => {
  const { images } = props;

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };

  // get from https://picsum.photos/v2/list 
  const [imageList, setImageList] = useState<GalleryItemProps[]>([]);

  useEffect(() => {
    const widthRange = [800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000];
    const heightRange = [800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000];

    const getRandomInt = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const fetchImageList = () => {

      for (let i = 0; i < 10; i++) {

        const width = getRandomInt(widthRange[0], widthRange[widthRange.length - 1]);
        const height = getRandomInt(heightRange[0], heightRange[heightRange.length - 1]);
        const url = `https://picsum.photos/${width}/${height}`;

        const image = {
          id: i,
          alt: `Image ${i}`,
          width: width,
          height: height,
          url: url,
          download_url: url
        };
        setImageList(imageList => [...imageList, image]);
      }

    };

    fetchImageList();
  }, []);

  return (
    <div id={"content"} className={"my-6 px-20"}>
      <section className={"my-10"}>
        <PageTitle title={"Gallery"} />
      </section>
      <section className={"my-10"}>
      <Masonry
          breakpointCols={breakpointColumnsObj}
          className={style.my_masonry_grid}
          columnClassName={style.my_masonry_grid__column}>
          {imageList.map((image) => (
            <figure key={image.id} className={style.figure + " relative cursor-pointer p-5"}>
                <img src={image.download_url} alt={image.alt} />
                <figcaption className={style.figure__caption + " absolute text-lg text-white"}>
                    <p>Generated image for placeholder purposes.</p>
                </figcaption>
              
            </figure>
          ))}
        </Masonry>
      </section>
      
    </div>
  );
};

export default Gallery;

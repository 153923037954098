import { Link } from "react-router-dom";
import SectionTitle from "../Components/SectionTitle";
import PageTitle from "../Components/PageTitle";

function PrivacyPage() {
  return (
    <div id={"content"} className={"container sm:px-0 lg:px-20 mx-auto"}>
      <section className={"container mx-auto sm:px-0 lg:px-20 py-10"}>
        <PageTitle title={"Privacy Policy"} />
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <p className={"mb-3"}>No <b>João Rodrigues - com til!</b>, acessível através de <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://xn--joorodrigues-3bb.pt" target="_blank">https://joãorodrigues.pt</a> (<i>https://xn--joorodrigues-3bb.pt</i>), uma das minhas prioridades principais é a <b>privacidade dos visitantes</b>. Este documento de <b>Política de Privacidade</b> contém tipos de informação que são recolhidos e gravados pela visita ao site, e como a utilizo.</p>
        <p className={"mb-3"}>Se existem questões adicionais ou requerer mais informação sobre a Política de Privacidade, não hesite em contactar através da página de Contactos ou enviando email para <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="mailto:eu@xn--joorodrigues-3bb.pt">eu@joãorodrigues.pt</a>.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Ficheiros de Registo"} underlined={false} />
        <p className={"mb-3"}>O website segue um procedimento padrão na utilização de ficheiros de registo. Estes ficheiros registam visitantes quando entram no website. Todas as empresas de alojamento realizam esta ação, e é uma parte da analítica de serviços de alojamento.</p>
        <p className={"mb-3"}>A informação registada pelos ficheiros de registo inclui:</p>
        <ul className={"list-disc mb-5 space-y-1 list-inside"}>
          <li>endereços IP;</li>
          <li>tipo de navegador;</li>
          <li>o Provedor de Serviços de Internet (ISP);</li>
          <li>a data e hora de registo;</li>
          <li>as páginas referentes de entrada e as páginas de saída; e</li>
          <li>o número de cliques.</li>
        </ul>
        <p className={"mb-3"}>
          Estas informações não estão interligadas a nenhuma informação que seja pessoalmente identificável.
          O objetivo desta informação serve somente para analisar destaques, administrar o website, listar os movimentos de um utilizar no website,
          e obter informação demográfica.
        </p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
      <SectionTitle title={"Cookies e Web Beacons"} underlined={false} />
        <p className={"mb-3"}>Como qualquer website, o <i>João Rodrigues - com til!</i> usa 'cookies'. Estes cookies servem para armazenar informação
          incluindo a preferência dos visitantes, e as páginas no website que o visitante acedeu ou visitou.
          A informação é utilizada para otimizar a experiência do utilizador por customizar o conteúdo das páginas Web baseado
          no tipo de navegador no qual o visitante se encontra e/ou outro tipo de informações.</p>
        <p className={"mb-3"}>Para uma informação geral sobre os cookies, por favor leia <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies" do Cookie Consent</a> (<i>inglês</i>).</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Cookies de Terceiros"} underlined={false} />
        <p className={"mb-3"}>O <b>Google</b> é um dos serviços de terceiros no website. Este utiliza cookies, conhecidos como <i>DART cookies</i>,
          que servem para fornecer anúncios aos visitantes do website, baseado nas suas visitas a este website a outros na internet.
          Porém, visitantes podem optar por negar a utilização de DART cookies ao visitar a página da Política de Privacidade de Anúncios e Conteúdo da Google no seguinte endereço – <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
        <p className={"mb-3"}>Os comentários deste website são geridos pela plataforma <b>Disqus</b>, podendo visualizar a <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://disqus.com/data-sharing-settings/" target="_blank">Política de Cookies do Disqus</a> para esclarecer eventuais dúvidas sobre os cookies usados.</p>
      </section>
      
      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Políticas de Privacidade de Terceiros"} underlined={false} />
        <p className={"mb-3"}>Deve consultar esta lista para verificar a Política de Privacidade para cada um dos serviços de terceiros presentes no <i>João Rodrigues - com til!</i>.</p>
        <p className={"mb-3"}>Os comentários deste website são geridos pela plataforma <b>Disqus</b>, podendo visualizar a <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://help.disqus.com/en/articles/1717103-disqus-privacy-policy" target="_blank">Política de Privacidade do Disqus</a> para esclarecer eventuais dúvidas sobre a política de privacidade deste serviço e os cookies usados.</p>
        <p className={"mb-3"}>Este website usa a <b>Google Analytics</b>, que é uma das soluções de análise mais difundidas e confiáveis para ajudar-nos a entender como usa o site e como podemos melhorar a sua experiência. Estes cookies podem rastrear coisas como o tempo que passa no site e as páginas visitadas para que possamos continuar a produzir conteúdo atraente. Estes dados poderão ser associados a uma Conta Google previamente utilizada, de forma a poderem ser identificadas as formas de utilização do website. Saiba mais sobre as <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://policies.google.com/privacy" target="_blank">Políticas da Google Analytics</a>.</p>
        <p className={"mb-3"}>Para além disso, este website utiliza também o serviço de DNS da <b>CloudFlare</b>, por forma a tornar mais rápida e segura a experiência do utilizador. Caso necessite de obter informações, pode visualizar a <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://www.cloudflare.com/privacypolicy/">Política de Privacidade da CloudFlare</a>.</p>
        <p className={"mb-3"}>O <i>João Rodrigues - com til!</i> não tem acesso nem controlo sobre esses cookies, utilizados por terceiros.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Informação sobre Crianças"} underlined={false} />
        <p className={"mb-3"}>Outra parte da nossa prioridade é adicionar proteção para crianças ao utilizar a Internet. Encorajamos a tutores e pais para observar,
          e participar, e/ou monitorizar e guiar as crianças na sua atividade online.</p>
        <p className={"mb-3"}>Este website não coleta nenhuma informação pessoal identificável de crianças abaixo dos 13 anos de idade.
          Se acha que a sua criança forneceu este tipo de informações no website, altamente encorajamos a que nos contacte imediatamente
          através do formulário de contacto na <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="contacts.html">página de Contactos</a> e faremos o nosso melhor para
          remover essa informação dos registos.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Consentimento"} underlined={false} />
        <p className={"mb-3"}>Ao utilizar o website, estará a consentir com a Política de Privacidade e aceita os seus <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="terms.html">Termos e Condições</a>.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <p><strong>Última alteração:</strong> 2023-02-11 15:36:46</p>
        <p>Podem ocorrer alterações a estas políticas sem aviso prévio.</p>
      </section>
    </div>
  );
}

export default PrivacyPage;
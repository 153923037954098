import { Link } from "react-router-dom";
import SectionTitle from "../Components/SectionTitle";
import PageTitle from "../Components/PageTitle";

function TermsPage() {
  return (
    <div id={"content"} className={"container sm:px-0 lg:px-20 mx-auto"}>
      <section className={"container mx-auto sm:px-0 lg:px-20 py-10"}>
        <PageTitle title={"Termos e Condições"} />
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <p className={"mb-3"}>Bem-vindo ao <b>João Rodrigues - com til!</b>!</p>
        <p className={"mb-3"}>Estes <b>Termos e Condições</b> definem as regras e regulamentações na utilização do website, localizado em <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://xn--joorodrigues-3bb.pt">https://joãorodrigues.pt</a> (<i>https://xn--joorodrigues-3bb.pt</i>).</p>
        <p className={"mb-3"}>Ao aceder este website, assumo que aceita estes <b>Termos e Condições</b>. Não continue a utilizar este website se não concorda com todos os <b>Termos e Condições</b> descritos nesta página.</p>
        <p className={"mb-3"}>A seguinte terminologia aplica-se a estes <b>Termos e Condições</b> e aos restantes acordos: "Utilizador", "Tu", "Teu", "Teus", "Seu", "Seus" e "Si" referem-se a si, a pessoa que entra ou interage neste website, e em acordo com estes Termos e Condições. "João Rodrigues - com til!", "Website", "Meu", "Nosso", "Eu" e "Nós", referem-se à pessoa representativa deste website, ao website e aos serviços de alojamento do mesmo.
          Todos os termos encontram-se em concordância, ou sujeitos ao respeito, da Legislação Portuguesa. Qualquer utilização da terminologia acima representada, ou outras palavras no singular ou plural, capitalização e/ou ele/ela ou eles/elas, são levados como alternativas, sendo dessa forma, referentes ao mesmo.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Cookies"} underlined={false} />
        <p className={"mb-3"}>É empregue a utilização de cookies. Ao aceder ao website, concorda com a política de utilização de cookies em concordância com a <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="pp.html">Política de Privacidade</a> em vigor. </p>
        <p className={"mb-3"}>A maioria dos websites interativos utiliza cookies para permitir a obtenção os detalhes do utilizador para cada visita.
          Os cookies são utilizados pelo website para permitir a funcionalidade de áreas específicas para permitir melhorar a acessibilidade
          de pessoas que visitam o website. Alguns dos parceiros de anúncios ou afiliados podem também usar cookies.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Licença"} underlined={false} />

        <p className={"mb-3"}>A menos que indicado o contrário, tudo o que se encontra neste website, para além das aplicações de terceiros, é detido sobre direitos sobre propriedade intelectual do João Rodrigues - com til!. Todos os direitos de propriedade intelectual são reservados. Pode ter acesso a estes conteúdos para utilização pessoal, sujeito às restrições definidas nos presentes Termos e Condições.</p>
        <p className={"mb-3"}>Não deve:</p>
        <ul className={"list-disc mb-5 space-y-1 list-inside"}>
          <li>Publicar material do <b>João Rodrigues - com til!</b> sem os devidos créditos referentes</li>
          <li>Vender, alugar ou colocar sobre licença material do <b>João Rodrigues - com til!</b></li>
          <li>Reproduzir, duplicar ou copiar, sem os devidos créditos, material do <b>João Rodrigues - com til!</b></li>
          <li>Redistribuir, sem os devidos créditos, conteúdo de <b>João Rodrigues - com til!</b></li>
        </ul>
        <p className={"mb-3"}>Os termos acima indicados são apenas definidos para o website em questão, não fazendo correspondência com os artigos publicados em <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="https://nadaradical.com" target="_blank">NadaRadical.com</a>, mencionados no blog do website.</p>
        <p className={"mb-3"}>Este acordo inicia a partir da data de publicação.</p>
        <p className={"mb-3"}>Partes do website oferecem uma oportunidade aos utilizadores para publicar e partilhar opiniões e informação em determinadas áreas do website.
          <br /><b>João Rodrigues - com til!</b> não filtra, edita, publica ou revê comentários antes da sua presença no website. Comentários não refletem os pontos de vista
          ou opiniões por parte do website, os seus agentes e/ou afiliados. <br />
          Comentários refletem pontos de vista e opiniões da pessoa que publica os seus pontos de vista e opiniões.
          Para o permitido às leis aplicáveis, <b>João Rodrigues - com til!</b> não se deve fazer corresponder aos
          comentários publicados por qualquer fraqueza ou danos que possam ser causados e/ou sofridos do resultado de, e/ou alguma publicação
          de, e/ou aparência dos comentários deste website.</p>
        <p className={"mb-3"}><b>João Rodrigues - com til!</b> reserva o direito a monitorizar todos os comentários e remover qualquer comentário que possam ser
          considerados inapropriados, ofensivos ou que desrespeitem os Termos e Condições.</p>
        <p className={"mb-3"}>É garantido e representado por si que:</p>
        <ul className={"list-disc mb-5 space-y-1 list-inside"}>
          <li>É lhe atribuída a responsabilidade pelos comentários publicados no website e ter todas as licenças e consentimentos para tal;</li>
          <li>Os comentários não desrespeitam qualquer direito de propriedade intelectual, incluindo sem limitação direitos de autor,
            patentes ou marcas de terceiros;</li>
          <li>Os comentários não podem conter qualquer comportamento difamatório, calunioso, ofensivo, indecente ou qualquer material
            que desrespeite a Legislação Portuguesa, em caso contrário, material que seja uma invasão da privacidade do próximo ou ilícitos.</li>
          <li>Os comentários não podem ser usados para solicitar ou promover negócios, ou customizados ou apresentar atividade
            comercial ou atividades ilícitas.</li>
          <li>É permitida a partilha de conteúdos públicos e de caráter de livre utilização, bem como de conhecimento.</li>
        </ul>

        <p className={"mb-3"}>Garante ao <i>João Rodrigues - com til!</i> uma licença não-exclusiva para utilização, reprodução, edição e autorização
          para outros utilizarem, reproduzirem e editarem qualquer um dos comentários de toda e qualquer forma,
          formatos ou multimédia necessários.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Referências ao conteúdo"} underlined={false} />
        <p className={"mb-3"}>Qualquer pessoa ou entidade, sem meio difamatório ou de associação ao que não corresponde da realidade defendida pelo mesmo, pode realizar referência por via de qualquer
          tipo de hiperligações.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"iFrames"} underlined={false} />
        <p className={"mb-3"}>Sem qualquer tipo de autorização ou permissão escrita, não deve criar frames em torno das páginas Web presentes neste website
          que alterem em qualquer forma o aspeto visual ou aparência do website.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Sobre a sua privacidade"} underlined={false} />

        <p className={"mb-3"}>Por favor, leia a nossa <a className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"} href="pp.html">Política de Privacidade</a> por forma a clarificar qualquer tipo de dúvidas que possam surgir
          da exigência por parte do utilizador,</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Direitos reservados"} underlined={false} />

        <p className={"mb-3"}>É lhe reservado o direito a requisitar que sejam removidos todas as hiperligações ou qualquer hiperligação específica
          do nosso website, ou qualquer tipo de conteúdos a que lhe pertençam os direitos de autor.
          Após qualquer requisição, será aprovado da sua parte a remoção dos mesmos do nosso website.
          Também é reservado o direito para corrigir estes termos e condições a qualquer altura, bem como a política de
          referências. Por continuamente endereçar este Website, concorda em aceitar e seguir estes termos de direitos reservados.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Remoção de hiperligações do nosso Website"} underlined={false} />
        <p className={"mb-3"}>Se qualquer link no nosso website for ofensivo, por qualquer motivo, é lhe dado o direito de contactar através dos meios
          preferenciais e informar sobre tal, a qualquer momento. Poderemos considerar ou não considerar, dependendo do motivo
          a que foi atribuído, e não é obrigação do Website fazê-lo ou a responder-lhe diretamente.</p>
        <p className={"mb-3"}>Não é assegurado que a informação no website esteja inteiramente correta, não asseguramos a sua completa veracidade nem
          que esteja de forma completa; nem prometemos que o website se mantenha disponível ou que o material no website
          esteja sempre atualizado.</p>
      </section>

      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <SectionTitle title={"Aviso Legal"} underlined={false} />

        <p className={"mb-3"}>Para maximizar ao limite permitido pela lei aplicável,
          excluímos todas as responsabilidades, garantias e condições relacionadas com o Website e a utilização do mesmo.
          Nada neste aviso legal irá:</p>
        <ul className={"list-disc mb-5 space-y-1 list-inside"}>
          <li>limitar ou excluir o nosso ou o seu risco por danos pessoal, físico ou psicológico;</li>
          <li>limitar ou excluir o nosso ou o seu risco por representação fraudulenta;</li>
          <li>limitar qualquer dos nossos riscos de qualquer forma que não seja permitida pela lei aplicável; ou</li>
          <li>excluir qualquer um dos riscos que não sejam excluídos pela lei aplicável.</li>
        </ul>
        <p className={"mb-3"}>As limitações e proibições de risco definidas nesta Secção e em qualquer local do aviso legal:
          (a) estão sujeitos ao parágrafo precedente; e (b) define todos os riscos consequentes perante o aviso legal, incluindo
          riscos presentes em contrato, em detorpação e por quebra do estatuto legal.</p>
        <p className={"mb-3"}>A menos que as informações do website não sejam fornecidas gratuitamente, não nos responsabilizamos
          por qualquer perda ou dano de qualquer natureza.</p>
      </section>
      <section className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
        <p><strong>Última alteração:</strong> 2023-02-11 15:34:41</p>
        <p>Podem ocorrer alterações a estes termos sem aviso prévio.</p>
      </section>
    </div>
  );
}

export default TermsPage;